

.nav-bar-links{
    color: #1B512D;
    
}


.nav-link:hover{
    text-shadow: 0px 0px 4px #551565;
}

.active{
    text-shadow: 0px 0px 2px #551565;
}