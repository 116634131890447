
.main-container{
    margin-top: 7vh;
}

.h1-title{
    font-size: 2rem;
    text-align: center;
}
.h2-title{
    font-size: 1.5rem;
    text-align: center;
    color: gray;
}