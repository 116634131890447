

.webdev-page{
    .header{
       //
    }
    .card-title{
        font-size: 1.75em !important;
        color: #1B512D;
        text-shadow: 1px 1px .5px #776972;
    }
    .card-text{
        font-size: 1em;
    }

    .how-it-works{
        background: linear-gradient(to left, #F0D7F7 ,#D9F2E2);
        border-radius: 15px;
        text-shadow: .1px .1px .5px white !important;
    }
    .rounded-5{
        border-radius: 15px;
    }

    i{
        text-shadow: none;
    }

    i:not(.no-hover):hover{
       // color: initial;
        transform: none;
        //text-shadow: 3px 3px 3px #776972;
        text-shadow: none;
        transition: none;
        cursor:default;
    }
    .icons-top{
        //background-color: #0E0311;
        border-radius: 15px 15px 0px 0px;
    }
    .icons-bottom{
       // background-color: #0E0311;
        border-radius: 0px 0px 15px 15px;
    }
    
    .fa-js-square{
        color: #F0DB4F;
        background: #323330 !important;
        padding-left: 1px;
        padding-right: 1px;
        border: 1px solid #323330;
        border-radius: 5px;
        margin-top: 0px;
    }
    .fa-react{
        color: #61DBFB;
        padding-left: 1px;
        padding-right: 1px;
        margin-top: 0px;
    }
    .fa-angular{
        color: #a6120d;
        //background: #b3b3b3 !important;
        padding-left: 2px;
        padding-right: 2px;
       // border: 1px solid #b3b3b3;
        border-radius: 5px;
        margin-top: 0px;
    }
    .fa-bootstrap{
        color: #080135;
        //background: #323330 !important;
        padding-left: 1px;
        padding-right: 1px;
        //border: 1px solid #323330;
        border-radius: 5px;
        margin-top: 0px;
    }
    .fa-vuejs{
        color: #41B883;
        //background: #323330 !important;
        padding-left: 1px;
        padding-right: 1px;
        //border: 1px solid #323330;
        border-radius: 5px;
        margin-top: 0px;
    }
    .fa-html5{
        color: #e34c26;
        //background: #323330 !important;
        padding-left: 1px;
        padding-right: 1px;
        //border: 1px solid #323330;
        border-radius: 5px;
        margin-top: 0px;
    }
    .fa-css3{
        color: #264de4;
        //background: #323330 !important;
        padding-left: 1px;
        padding-right: 1px;
        //border: 1px solid #323330;
        border-radius: 5px;
        margin-top: 0px;
    }

    @media screen and (max-width: 620px){
        .header-image{
            min-height: 250px;
        }
        .header-subtitle{
            font-size: 1.1em;
        }
    }
    @media screen and (max-width: 500px){
        .header-image{
            min-height: 200px;
        }
        .header-subtitle{
            font-size: 1em;
        }
    }
   

}