
.header{
    background-image: linear-gradient(to right, #0E0311 , #0E0311 ,#1a2820 ,#1B512D,#1B512D);
    padding-top: 1px;
    padding-bottom: 2vw;
}

.header-subtitle{
    margin-top: 0px !important;
}

h1{
    font-size: 3em;
    text-shadow: .5px .5px .5px #0E0311;
}

h2{
    font-size: 2.5em;
    text-shadow: .5px .5px .5px #0E0311;
}

h3{
    font-size: 2em;
    font-weight: 700;
}

h4{
    font-size: 1.5em;
    font-weight: 400;
    text-shadow: .5px .5px .5px #0E0311;
}

p{
    font-size: 1.2em;
}

ul{
    list-style-type: disc;
    list-style-position: outside;
}
