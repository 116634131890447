
.home-page{
    margin-top: 2vh;
}

.book-now-mobile{
    display: none;
 }
.card-main{
    max-width: 400px;
    margin: 1vw;
    
}

.title-text {
    margin-top: 2vh;
    font-size: 3em;
    text-shadow: 2px 2px #0E0311;
}

.subtitle-text {
    font-size: 1.75em;
    text-shadow: 1px 1px #0E0311;
}


.book-now{
    max-width: 30%;
}

@media screen and (max-width: 888px) {
    .title-text {
        font-size: 2em !important;
    }
    
    .subtitle-text {
        font-size: 1em;
    }
}

@media screen and (max-width: 734px) {

    .book-now{
        max-width: 50%;
    }
}

@media screen and (max-width: 625px) {
    .title-text {
        font-size: 1.5em !important;
    }
    
    .subtitle-text {
        font-size: .75em;
    }
    .book-now{
        max-width: 70%;
        font-size: .7em;
        .card-title{
            font-size: 1.2em;
        }
    }
    .contact-button{
        font-size: 1em;
        margin-top: 0;
    }
}

@media screen and (max-width: 500px) {
    .title-text {
        font-size: 1.5em !important;
    }
    .subtitle-text {
        font-size: .75em;
    }
    .book-now{
       display: none;
    }
    .book-now-mobile{
        display: block;
        box-shadow: 0px 0px 3px white;
     }
    .contact-button{
        font-size: 1em;
        margin-top: 0;
    }
}