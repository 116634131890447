
.unsaved-alert{
    color: rgb(168, 0, 0);
    font-style: italic;
    padding: 2px;
}

.image-header{
    font-size: 1.2rem;
    display: block;
}

.image-frame{
   // border: solid 1px black;
    width: min-content;
    padding: 1em;
}

.upload-frame {
    width: 100%;
}