
.display-none{
    display: none !important;
}

@media screen and (max-width:500px) {
    .display-none-mobile{
        display: none !important;
    }
}

.consulting-page{
    .consult-bar{
        font-size: clamp(.8rem, 1.1vw, 2rem);
        border-bottom: 1px solid #F0E6EC;   
    }
    .consulting-link-box{
        background-color: #e2dfe1;
        min-width: fit-content;
    }
    .card-title{
        font-size: 1.6em;
        color: #1B512D;
        margin-bottom: .4vh;
    }
    .card-title:hover{
        transform: none;
        cursor:default;
        text-shadow: none;
    }
    .card-text{
        font-size: 1em;
    }
    .summary{
        font-style: italic;
        font-size: .7em;
        margin-top: 0;
        padding-top: 0;
        margin-bottom: 1vh;
    }  


    @media screen and (max-width:500px) {
        h2{
            margin-left: 2vw;
            margin-right: 2vw;
        }
        
    }

   
}

.modal-body .bullet-point{
    padding-bottom: 10px !important;
    line-height: 1.25em;
} 

.consulting-main-image{
    border-radius: 15px;
    box-shadow: 0px 0px 2px #333333;
}
.consulting-main-container{
    position: relative;
}
.consulting-main-overlay{
    position: absolute;
    width: 40%;
    height: 50%;
    top: 33%;
    left: 33%;
    opacity: 50%;
    cursor: pointer;
}

.hardware-images-column-2{
    padding-top: 1.5vw;
}

.no-hover:hover{
    cursor: default;
}

.h4-highlight{
    display: inline !important;
    width: fit-content;
}