* {box-sizing: border-box}

.container {
    position: relative;
   // width: 50%;
  //  max-width: 300px;
  }

/* Make the image to responsive */
.image {
  //  display: block;
  //  width: 100%;
  //  height: auto;
  }
  
  /* The overlay effect - lays on top of the container and over the image */
  .overlay {
    position: absolute;
    bottom: 0;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1;
    width: 100%;
    height: 100%;
    transition: .5s ease;
    opacity:0;
    color: white;
    font-size: 20px;
    padding: 20px;
    text-align: center;
  }

  .container:hover .overlay {
    opacity: .01;
  }