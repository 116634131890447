
.services-page{
background-color: #0E0311;
padding-bottom: 5vh;

}

.header{
    position: relative;
}

.header-image{
    min-height: 200px;
}

.header-overlay{
    position: absolute;
    top: 0%;
}

.header-title{
    margin-top: 3vw;
    margin-left: 15vw;
}

.header-subtitle{
    margin-top: 1vw;
    margin-left: 15vw;
    margin-right: 15vw;
}

i{
    color: #1B512D;
    text-shadow: 1px 1px 0px #0E0311;
    cursor: pointer;
}

i:not(.no-hover):hover{
    transform: scale(1.1);
    transition: .3s;
    transition-timing-function: ease-in-out;
    color: #551565;
    text-shadow: 3px 3px 10px #0E0311;
    
}

.h5:not(.no-hover):hover{
    cursor: pointer;
    transform: scale(1.01);
    transition: .2s;
    transition-timing-function: ease-in-out;
    color: #551565;
    text-shadow: 1px 1px 1px #0E0311;
}

.no-underline{
    text-decoration: none;;
}

.no-underline:not(.no-hover):hover{
    cursor: pointer;
    transform: scale(1.01);
    transition: .2s;
    transition-timing-function: ease-in-out;
    color: #551565;
    text-shadow: 1px 1px 1px #0E0311;
}

.quote{
    font-size: large;
}

@media screen and (max-width: 500px) {
.quote{
    width: 100% !important;
    font-size: x-large;
}
}