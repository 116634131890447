$primary: #5694de;
$gray-light: #f7fbff;
$aqua: #0cc0ef;


$dashBGColor: #f5f5f5;
$secondary: #35363a;
$titleActive: $primary;
$widget-header-BGColor: white;
$defaultWidgetShadow: #dcdcdc 0px 0px 10px;
$hoverWidgetShadow: black 4px 4px 15px;

.widget-panel {
    width: 100%;
    margin-top: 1%;

    .widget {
        background-color: white;
        border: 1px solid gray;
        border-radius: 1vw;
        box-shadow: #dcdcdc 0px 0px 10px;

        .header {
            padding-top: 0.3vw;
            padding-left: 0.3vw;
            padding-right: 0.3vw;
            border-radius: 1vw 1vw 0px 0px;
            border-bottom: 1px solid grey;
            text-align: center;
            font-size: larger;
            font-weight: bold;
            display: flex;
            justify-content: space-between;
        }

        .body {
            padding: 0.3vw;
            text-align: center;
            border-radius: 0px 0px 1vw 1vw;
        }
    }

    .widget:hover {
        box-shadow: $hoverWidgetShadow;
        cursor: pointer;
    }

    .widget-nohover:hover {
        box-shadow: $defaultWidgetShadow !important;
        cursor: auto;
    }

    .variable-table {
        min-height: 30vh;
        margin-bottom: 0;
        width: 100%;

        .mouse-enter {
            background-color: yellow;
        }
    }

    .count-widget {
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding-left: 5%;
            padding-right: 5%;
            padding-top: 2%;

            .left {
                text-align: left;
                margin-right: auto;
                width: 100%;

                .text {
                    padding-left: 5%;
                }

                .top {
                    color: gray !important;
                    font-size: 90% !important;
                    text-transform: uppercase;
                }

                .bottom {
                    color: black !important;
                }
            }
        }
    }

    .highlights {
        padding-top: 5vh;
    }

}