


.image-container{
    max-width: 250px;
    max-height: 184px;
    float: left;
    img{
        display: block;
        max-width: 100%;
    }
}

.img-preview{
    max-width: 100px;
    max-height: 100px;
    float: left;
    margin-left: 10px;
    img{
        display: block;
        max-width: 100%;
    }
}