.MultiSelectList {
    background-color: gray;
    border-style: solid;
    border-width: 1px;
}

.MultiSelectList-Item {
    position: relative;
    background-color: #FFF;
    display: block;
    border-style: solid;
    border-width: 1px;
    border-color: #FFF;
    margin: 2px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 10px;
    padding-right: 10px;
}

.MultiSelectList-Item:hover {
    background-color: rgb(254, 255, 187);
    border-style: solid;
    border-width: 1px;
    border-color: black;
    cursor: pointer;
}

.MultiSelectList-Item:active {
    background-color: rgb(251, 255, 0);
    border-style: solid;
    border-width: 1.5px;
    font-weight: lighter;
}

.item-active {
    background-color: rgb(251, 255, 0);
    font-weight: bolder;
    padding-left: 7px;
    padding-right: 7px;
}

.tooltip {
    font-size: 75%;
    position: absolute;
    width: 100%;
    height: fit-content;
    display: block;
    background-color: rgb(13, 202, 240);
    color: black;
    opacity: 0%;
    border-radius: 5px;
    top: 0;
    left: 0;
    white-space: pre-wrap;
    overflow-y: visible;
    text-overflow: ellipsis;
    word-wrap: normal;
    line-height: 1em;
    text-align: center;
    padding: 5px;
}

.tooltip-slow:hover {
    width: 100%;
    min-height: fit-content;
    opacity: 90%;
    transition: opacity 500ms ease-in-out 1s;
}

.tooltip-fast:hover {
    width: 100%;
    min-height: fit-content;
    opacity: 90%;
    transition: opacity 100ms ease-in-out 500ms;
}

.tooltip-very-fast:hover {
    width: 100%;
    min-height: fit-content;
    opacity: 90%;
    transition: opacity 100ms ease-in-out 200ms;
}

.tooltip-normal:hover {
    width: 100%;
    min-height: fit-content;
    opacity: 90%;
    transition: opacity 250ms ease-in-out 500ms;
}

.tooltip-instant:hover {
    width: 100%;
    min-height: fit-content;
    opacity: 90%;
}

.hide {
    display: none;
    opacity: 0%;
}