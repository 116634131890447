

.siterefurb-page{

    .header-title{
        padding-bottom: .2em;
        color: black !important;
    }
    .header-subtitle{
        color: #0E0311 !important;
    }

    .header-overlay{
        color: #0E0311 !important;
        background: linear-gradient(to left bottom, rgba(240, 230, 236, .7) , rgba(240, 230, 236, 0));
        height: 337px;
        width: 100%;
    }
    
    @media screen and (max-width: 600px) {
        .border{
            border: none !important;
        }
        h3{
            font-size: 1.7em;
            margin: auto;
        }
        h4{
            font-size: 1em;
            font-weight: 500;
            padding: 10px
        }
        h5{
            font-size: .95em;

        }
        p{
            font-size: .8em;
        }
    }
}