
//TODO: create media query to change hand direction and bounce direction for mobile.

@keyframes bounce {
    0% {
        transform: translatex(-20px) scale(1.0) rotate(0deg);
      }
      33% {
        transform: translatex(-15px) scale(1.2) rotate(-10deg);
      }
      67% {
        transform: translatex(20px) scale(.9) rotate(10deg);
      }
      100% {
        transform: translatex(-20px) scale(1.0) rotate(0deg);
      }
}

.bounce {
    color: red;
    animation-name: bounce;
    animation-duration: 3s;
    animation-direction: normal;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
   }