.close-button-main {
    display: flex;
    // border: 1px solid black !important;
    // border-radius: 3px;

    .image {
        max-width: 25px;
        max-height: 25px;
        // outline: 1px solid black !important;

    }

    .image:hover {
        filter: drop-shadow(2px 2px 1px #333);
    }

    .image:active {
        filter: drop-shadow(0px 0px 1px #000);
    }
}