
.main-container{
    padding-top: 2vh;
    padding-bottom: 2vh; 
}

.contact-form{
    max-width: 500px;
    margin-top: auto;
    margin-bottom: auto;
}


h1{
    font-size: 2em;
    font-weight: bold;
}

h2{
    font-size: 1.3em;
    font-weight: 400;
}

.input-text{
    padding-bottom: 1vh;
}

button{
    margin-top: 1vh;
}