.setup-page {
    .budget-amount-headers {
        background-color: white;
        border: 1px solid #ced4da;
        border-radius: 15px 15px 0px 0px;
        margin-left: .375rem;
        margin-right: .375rem;

        .header-amounts {
            display: flex;
            justify-content: flex-start;
            border-bottom: none;

            .title-amounts {
                font-weight: bold;
                white-space: nowrap;
                font-size: 95%;
                line-height: 150%;
                padding-top: 0.175rem;
                padding-right: 0.75rem;
                padding-bottom: 0.175rem;
                padding-left: 0.75rem;
                margin-bottom: 0;
                //  margin-bottom: 0;
            }

            .title-pertotal {
                display: flex;
                align-items: center;
                margin: 0.5rem;
            }

            .pertotal {

                padding-top: 0.375rem;
                padding-right: 0.75rem;
                padding-bottom: 0.375rem;
                padding-left: 0.75rem;
            }

        }
    }
}