

.solutions-page{
    
    .row-container{
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
    }
    .row-overlay{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 100;
        background: radial-gradient(#776972, #776972, #F0E6EC, #F0E6EC);
        opacity: 45%;
        transition: opacity .3s;
        transition-timing-function: ease-in-out;
        border-radius: 15px;
    }
    .row-overlay-text{
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        font-size: 8em;
    }

    @media screen and (max-width: 625px) {
        .row-overlay-text{
            font-size: 4.5em;
        }
    }

    .display-none:hover{
        display: none !important;
    }
    
    .card-text{
        font-size: 1em;
    }

    .card-footer{
        small{
            font-size: .75em;
        }
    }

    .bg-fade{
        background: radial-gradient(#1B512D, #295237, #F0E6EC, #F0E6EC,);
        min-height: 1.5em;
    }

    .bg-fade-vert{
        background: linear-gradient(180deg, #1B512D, rgba(240, 230, 236, 0) 100%);
   

      //  linear-gradient(127deg, rgba(0,255,0,.8), rgba(0,255,0,0) 70.71%),
      //  linear-gradient(180deg, #F0E6EC, #1B512D, 70.71%);
        min-height: 1.5em;
    }
   
}